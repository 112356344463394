<template>
  <v-card tile :loading="loading" :disabled="disabled" flat transparent>
    <ul class="area-chart my-4 border">
      <li
        v-for="(item, index) in store.websiteScores"
        :key="item.total + item.date + index"
        :class="getValueClass(item.total)"
        :style="getItemStyle(index)"
      >
        <v-tooltip activator="parent" location="top">
          <div class="d-flex align-center">
            <score-circular size="34" :value="item.total"></score-circular>
            <div class="ml-2">{{ item.date }}</div>
          </div>
        </v-tooltip>
      </li>
    </ul>
  </v-card>
</template>

<script setup>
import { useStore } from '@/composables/store'

const store = useStore()

function getItemStyle(index) {
  let start, end

  // For the first item in the array
  if (index === 0) {
    start = store.websiteScores[index].total / 100
    end = start // Make start and end the same
  } else {
    // For subsequent items, calculate start and end normally
    start = store.websiteScores[index - 1].total / 100
    end = store.websiteScores[index].total / 100
  }

  // Calculate margin left, no change needed here
  const marginLeft = index === 0 ? '0' : `${-0.5 * index}px`

  return {
    '--start': start.toString(),
    '--end': end.toString(),
    'margin-left': marginLeft,
  }
}

const loading = computed(
  () => store.statusRequestFetchWebsiteStats === 'fetching',
)

const getValueClass = (value) => store.getStatus({ score: value })

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped lang="scss">
.area-chart {
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  width: 100%;
  height: var(--chart-height, 80px);
  display: flex;
  flex-direction: row;
  list-style: none;

  > li {
    flex: 1;
    position: relative;
    clip-path: polygon(
      0% calc(100% * (1 - var(--start))),
      100% calc(100% * (1 - var(--end))),
      100% 100%,
      0% 100%
    );

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%; /* Full height to apply clip-path effectively */
      background-color: #fc619d; /* Color of the line */
      clip-path: polygon(
        0% calc(100% * (1 - var(--start))),
        100% calc(100% * (1 - var(--end))),
        100% calc(100% * (1 - var(--end)) + 1px),
        /* Slightly offset for 1px line thickness */ 0%
          calc(100% * (1 - var(--start)) + 1px)
          /* Slightly offset for 1px line thickness */
      );
      z-index: 2;
    }

    &.error {
      background: #ffd4ff;
    }
    &.warning {
      background: #ff9adf;
    }
    &.success {
      background: #fc619d;
    }
  }
}
</style>
